export const neutral = {
  white: "hsl(216, 33%, 97%)",
  100: "hsl(214, 15%, 91%)",
  200: "hsl(210, 15%, 82%)",
  300: "hsl(211, 13%, 65%)",
  400: "hsl(211, 10%, 53%)",
  500: "hsl(211, 12%, 43%)",
  600: "hsl(209, 14%, 37%)",
  700: "hsl(209, 18%, 30%)",
  800: "hsl(209, 20%, 25%)",
  900: "hsl(210, 24%, 15%)",
}

export const red = {
  white: "hsl(353, 96%, 98%)",
  100: "hsl(353, 95%, 90%)",
  200: "hsl(353, 94%, 81%)",
  300: "hsl(352, 85%, 72%)",
  400: "hsl(355, 85%, 68%)",
  500: "hsl(357, 85%, 64%)",
  600: "hsl(354, 79%, 57%)",
  700: "hsl(349, 79%, 45%)",
  800: "hsl(349, 79%, 34%)",
  900: "hsl(349, 79%, 21%)",
}

export const teal = {
  white: "hsl(186, 47%, 97%)",
  100: "hsl(186, 50%, 92%)",
  200: "hsl(186, 56%, 88%)",
  300: "hsl(183, 51%, 79%)",
  400: "hsl(181, 41%, 69%)",
  500: "hsl(180, 32%, 57%)",
  600: "hsl(187, 32%, 54%)",
  700: "hsl(187, 35%, 47%)",
  800: "hsl(189, 35%, 39%)",
  900: "hsl(191, 38%, 24%)",
}

export const blue = {
  white: "hsl(192, 66%, 98%)",
  100: "hsl(192, 66%, 90%)",
  200: "hsl(191, 66%, 75%)",
  300: "hsl(194, 66%, 61%)",
  400: "hsl(195, 66%, 44%)",
  500: "hsl(198, 63%, 38%)",
  600: "hsl(202, 67%, 32%)",
  700: "hsl(208, 71%, 28%)",
  800: "hsl(208, 74%, 20%)",
  900: "hsl(208, 77%, 15%)",
}

export const yellow = {
  white: "hsl(49, 100%, 98%)",
  100: "hsl(48, 100%, 88%)",
  200: "hsl(48, 95%, 76%)",
  300: "hsl(48, 94%, 68%)",
  400: "hsl(44, 92%, 63%)",
  500: "hsl(42, 87%, 55%)",
  600: "hsl(36, 77%, 49%)",
  700: "hsl(29, 80%, 44%)",
  800: "hsl(22, 82%, 39%)",
  900: "hsl(15, 86%, 30%)",
}
